<template>
  <div>
    <form @submit.prevent="toggleModal">
      <loading-button :is-loading="false"> Add Product </loading-button>
    </form>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      size="sm"
      sticky-footer
      id="add-product"
      modal-title="Add product"
      ><div class="space-y-8">
        <div class="space-y-3">
          <key-value label="Name" class="col-span-full">
            <input type="text" class="w-full" v-model="product.name" />
          </key-value>
          <key-value label="Short Name" class="col-span-full">
            <input type="text" class="w-full" v-model="product.shortname" />
          </key-value>
          <key-value label="Type">
            <radio-buttons :options="foodTypes" v-model="product.foodtype" />
          </key-value>
          <key-value label="Category">
            <radio-buttons
              :options="foodCategorys"
              v-model="product.foodcategory"
            />
          </key-value>
          <key-value label="">
            <radio-buttons
              :options="typeCategorys"
              v-model="product.foodcomplete"
            />
          </key-value>
          <key-value label="Weight" v-if="product.foodtype !== 'dry'">
            <div class="flex space-x-2 items-center">
              <div class="w-20">
                <input type="text" v-model="product.size" />
              </div>
              <div class="text-gray-400 text-sm">g</div>
            </div>
          </key-value>
          <key-value label="SKU Suffix" class="col-span-full">
            <div class="flex space-x-2 w-full items-center">
              <div class="w-20">
                <input
                  type="text"
                  v-model="sku"
                  class="uppercase"
                  :class="{ 'has-error': skuExists }"
                />
              </div>
              <div v-if="skuExists" class="text-red-400 text-sm">
                SKU Already Exists
              </div>
              <div
                class="text-gray-500 flex-grow text-right"
                :class="{ 'text-red-400': skuExists }"
              >
                {{ fullSku }}
              </div>
            </div>
          </key-value>
          <div class="py-2"><hr /></div>
          <div class="font-semibold mb-2 text-left">Lead Times</div>
          <key-value label="PO" class="col-span-full">
            <div class="flex space-x-2 items-center">
              <div class="w-20">
                <input type="text" v-model="product.leadtime" />
              </div>
              <div class="text-gray-400 text-sm">weeks</div>
            </div>
          </key-value>
          <key-value label="Delivery" class="col-span-full">
            <div class="flex space-x-2 items-center">
              <div class="w-20">
                <input type="text" v-model="product.etdtime" />
              </div>
              <div class="text-gray-400 text-sm">weeks</div>
            </div>
          </key-value>
          <div class="py-2"><hr /></div>
          <div class="font-semibold mb-2 text-left">Details</div>
          <key-value label="Strapline" items-start class="col-span-full">
            <textarea
              class="w-full"
              type="text"
              v-model="product.strapline"
            ></textarea>
          </key-value>
          <key-value label="Description" items-start class="col-span-full">
            <textarea
              class="w-full"
              type="text"
              v-model="product.description"
            ></textarea>
          </key-value>
          <key-value label="Composition" items-start class="col-span-full">
            <textarea
              class="w-full"
              type="text"
              v-model="product.composition"
            ></textarea>
          </key-value>
          <key-value
            label="Nutritional Additives"
            items-start
            class="col-span-full"
          >
            <textarea
              class="w-full"
              type="text"
              v-model="product.additives"
            ></textarea>
          </key-value>
          <key-value
            label="Analytical Constituents"
            items-start
            class="col-span-full"
          >
            <textarea
              class="w-full"
              type="text"
              v-model="product.constituents"
            ></textarea>
          </key-value>
        </div>
        <div
          class="flex space-x-4 sticky bottom-0 px-8 left-0 right-0 py-8 w-full bg-white"
        >
          <form @submit.prevent="addProduct">
            <loading-button
              primary
              :is-enabled="true"
              :is-loading="isLoading"
              label="Add Product"
            />
          </form>
          <form @submit.prevent="toggleModal('cancel')">
            <loading-button label="Cancel" secondary />
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
// import DropdownButton from '@/components/common/ui/DropdownButton';
import RadioButtons from '@/components/common/ui/RadioButtons';
import { mapGetters } from 'vuex';
import { trim, upperCase } from 'lodash/fp';
import KeyValue from '@/components/common/ui/KeyValue.vue';

export default {
  components: {
    Modal,
    LoadingButton,
    // DropdownButton,
    RadioButtons,
    KeyValue
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      product: {
        foodtype: 'wet_pouch',
        foodcategory: 'adult',
        foodcomplete: false
      },
      sku: '',
      foodTypes: [
        {
          value: 'wet_pouch',
          label: 'Pouch'
        },
        {
          value: 'wet_can',
          label: 'Can'
        },
        {
          value: 'wet_smooth',
          label: 'Smooth'
        },
        {
          value: 'wet_tray',
          label: 'Tray'
        },
        {
          value: 'wet_chunk',
          label: 'Tasty Chunks'
        },
        {
          value: 'dry',
          label: 'Dry'
        }
      ],
      foodCategorys: [
        {
          value: 'kitten',
          label: 'Kitten'
        },
        {
          value: 'adult',
          label: 'Adult'
        }
      ],
      typeCategorys: [
        {
          value: true,
          label: 'Complete'
        },
        {
          value: false,
          label: 'Complementary'
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      products: 'getProducts'
    }),
    skuPrefix() {
      if (this.product.foodtype === 'dry') {
        return `D_BAG_`;
      }
      if (this.product.foodtype === 'wet_pouch') {
        return `W_PCH_`;
      }
      if (this.product.foodtype === 'wet_chunk') {
        return `W_PCH_`;
      }
      if (this.product.foodtype === 'wet_tray') {
        return `W_TRA_`;
      }
      if (this.product.foodtype === 'wet_can') {
        return `W_CAN_`;
      }
      if (this.product.foodtype === 'wet_smooth') {
        return `W_CAN_`;
      }
      return false;
    },
    increment() {
      return this.product.foodtype === 'dry' ? 800 : 5;
    },
    fullSku() {
      return `${this.skuPrefix}${this.sku.toUpperCase()}${
        this.product.foodcategory === 'kitten' ? '_KIT' : ''
      }`;
    },
    skuExists() {
      if (this.products) {
        return this.products.filter(p => p.sku === this.fullSku).length > 0;
      }
      return false;
    }
  },
  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },

    addProduct() {
      this.isLoading = true;
      const self = this;
      this.product.foodcomplete = this.product.foodcomplete.value;
      const data = {
        ...this.product,
        sku: this.fullSku,
        increments: this.increment,
        image: trim(upperCase(this.fullSku))
      };

      data.leadtime = data.leadtime * 7;
      data.etdtime = data.etdtime * 7;

      this.$store
        .dispatch('addProduct', data)
        .then(response => {
          console.log(response);
          this.isLoading = false;
          this.product = {
            foodtype: 'wet_pouch',
            foodcategory: 'adult'
          };

          const id = response.data.content[0].id;

          this.$store.dispatch('getProducts').then(() => {
            self.toggleModal();
            self.$router.push({
              name: 'productProfile',
              params: { id: id, tab: 'overview' }
            });
          });
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    }
  },
  mounted() {}
};
</script>

<style></style>
